import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/Admin",
    name: "HomeAdmin",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/ResetPassword/:token/:email",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/PerfilUsuario",
    name: "PerfilUsuario",
    component: () => import("../views/perfil/PerfilUsuario.vue"),
  },

  {
    path: "/Contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },

  {
    path: "/CasosSoporte",
    name: "CasosSoporte",
    component: () => import("../views/CasosSoporte.vue"),
  },

  {
    path: "/CasosSoporteAutorizaciones",
    name: "CasosSoporteAutorizaciones",
    component: () => import("../views/CasosSoporteAutorizaciones.vue"),
  },

  {
    path: "/Hidrocarburos",
    name: "Hidrocarburos",
    component: () => import("../views/Contact.vue"),
  },

  {
    path: "/tep",
    name: "tep",
    component: () => import("../views/dashboard/tep.vue"),
  },

  {
    path: "/tif",
    name: "tif",
    component: () => import("../views/Contact.vue"),
  },

  {
    path: "/FuncionariosFrontera",
    name: "FuncionariosFrontera",
    component: () => import("../views/Contact.vue"),
  },

  {
    path: "/EvaluacionConductor",
    name: "EvaluacionConductor",
    component: () => import("../views/EvaluacionConductor.vue"),
  },

  {
    path: "/MaterialEvaluacion",
    name: "MaterialEvaluacion",
    component: () => import("../views/MaterialEvaluacion.vue"),
  },

  {
    path: "/Comercializacion",
    name: "Comercializacion",
    component: () => import("../views/com/dashboard/ComDashboardIndex.vue"),
  },

  {
    path: "/PoliticaTratamientoDatos",
    name: "PoliticaTratamientoDatos",
    component: () => import("../views/PoliticaPrivacidad.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "MaterialEvaluacion" &&
    to.name !== "EvaluacionConductor" &&
    to.name !== "Login" &&
    to.name !== "ForgotPassword" &&
    to.name !== "ResetPassword" &&
    to.name !== "PoliticaTratamientoDatos" &&
    !store.getters.isAuthenticated
  )
    next({ name: "Login" });
  next();
});

export default router;
