import Api from "./../../services/apiList";

export default {
  namespaced: true,
  state: {
    postulacionesTep: [],
    postulacionTep: [],
  },
  getters: {},
  mutations: {
    setPostulaciones: (state, postulacionesTep) => {
      state.postulacionesTep = postulacionesTep;
    },

    setLoading: (state, loading) => {
      state.loading = loading;
    },

    setPostulacion: (state, postulacionTep) => {
      state.postulacionTep = postulacionTep;
    },

    setPostulacionesShow: (state, postulacionTepShow) => {
      state.postulacionTepShow = postulacionTepShow;
    },

  },
  actions: {
    /*
     * TEP POSTULACIÓN
     */
    actGetPostulaciones: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.getPostulacionesTep(data);
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setPostulaciones", response);
      return true;
    },

    actGetPostulacion: async ({ commit }, id) => {
      commit("setLoading", true);
      let response = await Api.getPostulacionTep(id);
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setPostulacion", response);
      return true;
    },

    actGetPostulacionesShow: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.getPostulacionesTepShow(data);
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setPostulacionesShow", response);
      return true;
    },

    actSavePostulacion: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.savePostulacionTep(data);
      commit("setLoading", false);
      return response;
    },

    actSavePostulacionDet: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.savePostulacionTepDet(data);
      commit("setLoading", false);
      return response;
    },

    actEditPostulacion: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.editPostulacionTep(data);
      commit("setLoading", false);
      return response;
    },

    actDeletePostulacion: async ({ commit }, id) => {
      commit("setLoading", true);
      let response = await Api.deletePostulacion(id);
      commit("setLoading", false);
      return response;
    },

    actMailPostulacionesTep: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.mailPostulacionesTep(data);
      commit("setLoading", false);
      return response;
    },
  },
};
