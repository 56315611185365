import AxiosFunctions from "./axiosFunctions";

/* all URI to API */
const uri = {
  getRolesUri: "/admin/roles",
  getPermisosUri: "/admin/permisos",
  savePermisosUri: "/admin/permisos/asignar-desasignar",
  getCiudadesUri: "/admin/ciudades?select=true",
  getListasUri: "/lista",
  getSitiosUri: "/admin/sitios/lista",
  getInspeccionUri: "/admin/formatosInspeccion?select=true",
  savePuntoGestionUri: "/admin/puntosGestion",
  getNominacionesUri: "/hidrocarburos/nominacion",
  getNomRegaliasUri: "/hidrocarburos/nominacionesRegalias",
  getBloqueSitiosUri: "/hidrocarburos/bloques/sitios",
  saveNominacionDetUri: "/hidrocarburos/nominacion/detail",
  getEmpresasUri: "/admin/empresas/lista",
  getEmpresasNomUri: "/hidrocarburos/nominacion/empresas",
  saveDetNomEmpresaUri: "/hidrocarburos/det_nominacion/empresa",
  uploadFileUri: "/hidrocarburos/nominacion/importar",
  downloadFileUri: "/hidrocarburos/nominacion/exportar",
  getProgramacionesUri: "/hidrocarburos/programacion",
  getProductosUri: "/admin/productos/lista",
  getTiposVehiculosUri: "/admin/tiposVehiculos/lista",
  saveCitaUri: "/hidrocarburos/programacion/cita",
  getNominacionesListUri: "/hidrocarburos/nominacion/list",
  getNominacionesListFechaUri: "/hidrocarburos/nominacion/listFecha",
  getTreeProgramacion: "/hidrocarburos/programacion/tree",
  getPostulacionesTepUri: "/tep/postulaciones",
  getPostulacionesTepDetUri: "/tep/postulaciones/saveDet",
  getPostulacionesTepShowUri: "/tep/postulaciones/show",
  mailPostulacionesTepUri: "/tep/postulaciones/enviarCorreo",
};

export default {
  /*
   *
   * Roles
   *
   */
  getRoles: async () => {
    return await AxiosFunctions.get(uri.getRolesUri, {});
  },

  saveRoles: async (data) => {
    return await AxiosFunctions.post(uri.getRolesUri, data);
  },

  editRoles: async (data) => {
    let id = data[0];
    let params = { name: data[1], guard_name: data[2] };
    return await AxiosFunctions.patch(uri.getRolesUri + "/" + id, params);
  },

  deleteRoles: async (id) => {
    return await AxiosFunctions.delete(uri.getRolesUri + "/" + id, {});
  },

  /*
   *
   * Permisos
   *
   */
  getPermisos: async (id) => {
    return await AxiosFunctions.get(uri.getPermisosUri + "/" + id, {});
  },

  savePermisos: async (data) => {
    return await AxiosFunctions.post(uri.savePermisosUri, data);
  },

  /*
   *
   * Operaciones
   *
   */

  getCiudades: async () => {
    return await AxiosFunctions.get(uri.getCiudadesUri, {});
  },

  getListas: async (id) => {
    return await AxiosFunctions.get(uri.getListasUri + "/" + id, {});
  },

  getSitios: async () => {
    return await AxiosFunctions.get(uri.getSitiosUri, {});
  },

  getInspeccion: async () => {
    return await AxiosFunctions.get(uri.getInspeccionUri, {});
  },

  savePuntoGestion: async (data) => {
    return await AxiosFunctions.post(uri.savePuntoGestionUri, data);
  },

  getPuntosGestion: async (data) => {
    var page = data[0];
    var params = data[1];
    return await AxiosFunctions.get(uri.savePuntoGestionUri + "?page=" + page, {
      params: params,
    });
  },

  delPuntoGestion: async (id) => {
    return await AxiosFunctions.delete(uri.savePuntoGestionUri + "/" + id, {});
  },

  getPuntoGestion: async (id) => {
    return await AxiosFunctions.get(uri.savePuntoGestionUri + "/" + id, {});
  },

  updtPuntoGestion: async (data) => {
    let id = data[0];
    let params = data[1];
    return await AxiosFunctions.patch(
      uri.savePuntoGestionUri + "/" + id,
      params
    );
  },

  /*
   *
   * Hidrocarburos Nominaciones
   *
   */
  getNominaciones: async (data) => {
    var page = data[0];
    var params = data[1];
    return await AxiosFunctions.get(uri.getNominacionesUri + "?page=" + page, {
      params: params,
    });
  },

  saveNominacion: async (data) => {
    return await AxiosFunctions.post(uri.getNominacionesUri, data);
  },

  getBloqueSitios: async () => {
    return await AxiosFunctions.get(uri.getBloqueSitiosUri, {});
  },

  saveNominacionDet: async (id, params) => {
    return await AxiosFunctions.post(
      uri.saveNominacionDetUri + "/" + id,
      params
    );
  },

  getEmpresas: async () => {
    return await AxiosFunctions.get(uri.getEmpresasUri, {});
  },

  getEmpresasNom: async (data) => {
    return await AxiosFunctions.get(uri.getEmpresasNomUri, {
      params: data,
    });
  },

  saveDetNomEmpresa: async (idDetNom, idEmpresa, params) => {
    return await AxiosFunctions.post(
      uri.saveDetNomEmpresaUri + "/" + idDetNom + "/" + idEmpresa,
      params
    );
  },

  uploadFile: async (data) => {
    return await AxiosFunctions.post(uri.uploadFileUri, data);
  },

  editNominacion: async (data) => {
    let id = data[0];
    let params = data[1];
    return await AxiosFunctions.patch(
      uri.getNominacionesUri + "/" + id,
      params
    );
  },

  downloadFile: async () => {
    return await AxiosFunctions.get(uri.downloadFileUri, {
      responseType: "blob",
    });
  },

  deleteNominacion: async (id) => {
    return await AxiosFunctions.delete(uri.getNominacionesUri + "/" + id, {});
  },

  getNominacion: async (id) => {
    return await AxiosFunctions.get(uri.getNominacionesUri + "/" + id, {});
  },

  deleteDetNomEmpresa: async (idDetNom, idEmpresa) => {
    return await AxiosFunctions.delete(
      uri.saveDetNomEmpresaUri + "/" + idDetNom + "/" + idEmpresa,
      {}
    );
  },

  /*
   *
   * Hidrocarburos Programaciones
   *
   */

  getProgramaciones: async (data) => {
    var page = data[0];
    var params = data[1];
    return await AxiosFunctions.get(
      uri.getProgramacionesUri + "?page=" + page,
      { params: params }
    );
  },

  saveProgramacion: async (data) => {
    return await AxiosFunctions.post(uri.getProgramacionesUri, data);
  },

  getProductos: async () => {
    return await AxiosFunctions.get(uri.getProductosUri, {});
  },

  getTiposVehiculos: async () => {
    return await AxiosFunctions.get(uri.getTiposVehiculosUri, {});
  },

  getProgramacion: async (id) => {
    return await AxiosFunctions.get(uri.getProgramacionesUri + "/" + id, {});
  },

  saveCita: async (data) => {
    let id = data[0];
    let params = data[1];
    return await AxiosFunctions.post(uri.saveCitaUri + "/" + id, params);
  },



  deleteProgramacion: async (id) => {
    return await AxiosFunctions.delete(uri.getProgramacionesUri + "/" + id, {});
  },

  closeProgramacion: async (data) => {
    let id = data[0];
    let params = data[1];
    return await AxiosFunctions.patch(
      uri.getProgramacionesUri + "/" + id,
      params
    );
  },

  deleteCita: async (id) => {
    return await AxiosFunctions.delete(uri.saveCitaUri + "/" + id, {});
  },

  getNominacionesList: async () => {
    return await AxiosFunctions.get(uri.getNominacionesListUri, {});
  },

  getNominacionesListFecha: async () => {
    return await AxiosFunctions.get(uri.getNominacionesListFechaUri, {});
  },

  getTreeProgramacion: async (id) => {
    return await AxiosFunctions.get(uri.getTreeProgramacion + "/" + id, {});
  },

  /*
   *
   * TEP Postulaciones
   *
   */
  getPostulacionesTep: async (data) => {
    var page = data[0];
    var params = data[1];
    return await AxiosFunctions.get(uri.getPostulacionesTepUri + "?page=" + page, {
      params: params,
    });
  },

  getPostulacionTep: async (id) => {
    return await AxiosFunctions.get(uri.getPostulacionesTepUri + "/" + id, {});
  },

  getPostulacionesTepShow: async (data) => {    
    var params = data;
    return await AxiosFunctions.get(uri.getPostulacionesTepShowUri + "/" + data.nominacion_id, {
      params: params,
    });
  },
 
  savePostulacionTep: async (data) => {
    return await AxiosFunctions.post(uri.getPostulacionesTepUri, data);
  },

  savePostulacionTepDet: async (data) => {
    return await AxiosFunctions.post(uri.getPostulacionesTepDetUri, data);
  },

  editPostulacionTep: async (data) => {
    let id = data[0];
    let params = data[1];
    return await AxiosFunctions.patch(
      uri.getPostulacionesTepUri + "/" + id,
      params
    );
  },

  deletePostulacion: async (id) => {
    return await AxiosFunctions.delete(uri.getPostulacionesTepUri + "/" + id, {});
  },

  mailPostulacionesTep: async (data) => {
    return await AxiosFunctions.post(uri.mailPostulacionesTepUri, data);
  },

  /*
   *
   * Hidrocarburos Nominaciones
   *
   */
  getNomRegalias: async (data) => {
    var page = data[0];
    var params = data[1];
    return await AxiosFunctions.get(uri.getNomRegaliasUri + "?page=" + page, {
      params: params,
    });
  },
};
