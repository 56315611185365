import Vue from "vue";
import VueProgressBar from "vue-progressbar";
import VueSweetalert2 from "vue-sweetalert2";
import App from "./App.vue";
import { createApp } from 'vue'
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "vue-select/dist/vue-select.css";
import Vuelidate from "vuelidate";
import { VueSpinners } from "@saeris/vue-spinners";
import * as VueGoogleMaps from "vue2-google-maps";
import { VueReCaptcha } from 'vue-recaptcha-v3';
import HighchartsVue from 'highcharts-vue'
import Highcharts from "highcharts";
import * as HichartsExporting from 'highcharts/modules/exporting';
import * as HichartsExportingData from 'highcharts/modules/export-data';
import * as HichartsOfflineExporting from 'highcharts/modules/offline-exporting';
import SeriesLabel from "highcharts/modules/series-label";
import HC_more from 'highcharts/highcharts-more';
import VueInputMask from 'vue-inputmask';
import VuePivottable from 'vue-pivottable'
import 'vue-pivottable/dist/vue-pivottable.css'
import VueDraggable from 'vue-draggable'
import VueFeather from 'vue-feather';
import Emitter from 'tiny-emitter';
//import x5GMaps from 'x5-gmaps'
import VueGoogleHeatmap from 'vue-google-heatmap';
//editor
import CKEditor from "@ckeditor/ckeditor5-vue2";



/* import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKETS_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    wsPort: 6001,
    forceTLS: false,
    disableStats: true
}); */


HC_more(Highcharts);
SeriesLabel(Highcharts);
HichartsExporting(Highcharts);
HichartsExportingData(Highcharts);
HichartsOfflineExporting(Highcharts);

Vue.use(VueDraggable);
Vue.use(VueFeather);

Vue.use(VueReCaptcha, {
    siteKey: "6LcQzCoaAAAAAGtRzD-hkRooKkBAHFSxdbV9z6WO"
});

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyB08XuIlU5kSGhA1bI2IsX8DIR97ATJJ6E",
        libraries: ["places", "geometry"],
    }
});

//Vue.use(x5GMaps, { key: 'AIzaSyBB9WS42sLEXtZEGL3TcI9BgOXwdX0xMcE', libraries: ['places', 'visualization'] })
Vue.use(VueGoogleHeatmap, {
    apiKey: 'AIzaSyB08XuIlU5kSGhA1bI2IsX8DIR97ATJJ6E'
});

Vue.use(HighchartsVue);
Vue.use(VueInputMask.default);
Vue.use(VuePivottable);
const options = {
    color: "#201747",
    thickness: "5px",
    autoRevert: true
};

const optionsSwal = {
    confirmButtonColor: "#001871",
    cancelButtonColor: "#ff1c79"
};


Vue.use(VueProgressBar, options);
Vue.use(VueSweetalert2, optionsSwal);
Vue.use(Vuelidate);
Vue.use(VueSpinners);
Vue.config.productionTip = false;

store.dispatch("getUser");
store.dispatch("setRoutes");

const app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
//app.config.globalProperties.$msalInstance = {};
//app.config.globalProperties.$emitter = new Emitter();

//editor
//Vue.config.productionTip = false;
Vue.use(CKEditor);
