<template>
  <div>
    <nav
      class="main-header navbar navbar-expand navbar-white navbar-light text-xs"
      :class="checkDarkMode ? 'dark-mode text-white' : ''"
    >
      <!-- Left navbar links -->
      <ul
        class="navbar-nav"
        :class="checkDarkMode ? 'dark-mode text-white' : ''"
      >
        <li class="nav-item">
          <a
            class="nav-link"
            :class="checkDarkMode ? 'dark-mode text-white' : ''"
            data-widget="pushmenu"
            href="#"
            role="button"
            v-if="this.$route.name != 'Home'"
          >
            <i class="fas fa-bars"></i>
          </a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <router-link
            to="/"
            class="nav-link"
            :class="checkDarkMode ? 'dark-mode text-white' : ''"
          >
            <img src="/img/frontera-pegaso-ermes.png" style="width: 300px" />
          </router-link>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <router-link
            to="/"
            class="nav-link"
            :class="checkDarkMode ? 'dark-mode text-white' : ''"
            >Inicio</router-link
          >
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <a
            href="/Contact"
            class="nav-link"
            :class="checkDarkMode ? 'dark-mode text-white' : ''"
            >Contacto</a
          >
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <a
            href="/CasosSoporte"
            class="nav-link"
            :class="checkDarkMode ? 'dark-mode text-white' : ''"
            >Casos soporte</a
          >
        </li>
      </ul>
      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
        <!-- Notifications Dropdown Menu -->
        <li class="nav-item">
          <div class="custom-control custom-switch mt-1">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitchModDark"
              @change="$parent.darkModeChange(checkDarkMode)"
              v-model="checkDarkMode"
            />
            <label class="custom-control-label" for="customSwitchModDark">
              <i class="fas fa-adjust mt-1"></i>
            </label>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            :class="checkDarkMode ? 'dark-mode text-white' : ''"
            data-toggle="dropdown"
            href="#"
          >
            <i class="far fa-bell"></i>
            <span class="badge badge-warning navbar-badge">15</span>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span class="dropdown-item dropdown-header">15 Notifications</span>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <i class="fas fa-envelope mr-2"></i> 4 new messages
              <span class="float-right text-muted text-sm">3 mins</span>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <i class="fas fa-users mr-2"></i> 8 friend requests
              <span class="float-right text-muted text-sm">12 hours</span>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <i class="fas fa-file mr-2"></i> 3 new reports
              <span class="float-right text-muted text-sm">2 days</span>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item dropdown-footer"
              >See All Notifications</a
            >
          </div>
        </li>

        <li class="nav-item dropdown">
          <a
            class="nav-link"
            :class="checkDarkMode ? 'dark-mode text-white' : ''"
            data-toggle="dropdown"
            href="#"
          >
            <i class="far fa-user"></i>
            {{ this.$store.state.user.email }}
          </a>
          <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
            <div class="card card-widget widget-user-2 p-0 m-1">
              <!-- Add the bg color to the header using any of the bg-* classes -->
              <div class="widget-user-header bg-frontera mr-0">
                <!-- /.widget-user-image -->
                <h4 class="text-white">
                  <b>{{ this.$store.state.user.name }}</b>
                </h4>
                <hr style="border: 1px solid white" />
                <h5 class="text-white" style="display: inline">
                  <b>Cargo:</b>
                </h5>
                <h6 class="text-white" style="display: inline">
                  {{ this.$store.state.user.charge }}
                </h6>
                <h5
                  class="text-white"
                  style="display: inline"
                  v-if="this.$store.state.user.empresas.length > 0"
                >
                  <b>Empresa:</b>
                </h5>
                <h6
                  class="text-white"
                  style="display: inline"
                  v-if="this.$store.state.user.empresas.length > 0"
                >
                  {{ this.$store.state.user.empresas[0].razon_social }}
                </h6>
                <br />
                <h5 class="text-white" style="display: inline">
                  <b>Correo:</b>
                </h5>
                <h6 class="text-white" style="display: inline">
                  {{ this.$store.state.user.email }}
                </h6>
              </div>
              <div class="card-footer p-1">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a
                      href="#"
                      class="nav-link"
                      :class="checkDarkMode ? 'dark-mode text-white' : ''"
                    >
                      <router-link to="/PerfilUsuario">
                        <i class="fa fa-user"></i> Perfil
                      </router-link>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#"
                      class="nav-link"
                      data-toggle="modal"
                      data-target="#modal-actualizar-clave"
                      :class="checkDarkMode ? 'dark-mode text-white' : ''"
                    >
                      <i class="fa fa-lock"></i> Cambiar clave
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#"
                      @click="logout"
                      class="nav-link"
                      :class="checkDarkMode ? 'dark-mode text-white' : ''"
                    >
                      <i class="fa fa-window-close"></i> Salir
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="checkDarkMode ? 'dark-mode text-white' : ''"
            data-widget="fullscreen"
            href="#"
            role="button"
          >
            <i class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
      </ul>
    </nav>
    <UpdatePassword></UpdatePassword>
  </div>
</template>

<script>
import UpdatePassword from "../UpdatePassword";
import { PublicClientApplication } from "@azure/msal-browser";
export default {
  name: "NavBarTop",
  components: {
    UpdatePassword,
  },
  data() {
    return {
      checkDarkMode: false,
    };
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig
    );
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      await this.$router.push("/login");
      const accounts = await this.$msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        await this.$msalInstance
          .logout({})
          .then(async () => {})
          .catch((error) => {
            console.error(error);
          });
      }

      //redirect
    },
  },
  mounted() {},
};
</script>

<style>
@media (max-width: 767.98px) {
  .left {
    width: 230px;
  }

  .texto {
    font-size: 0.7em;
  }
}
</style>
