import Api from "./../../services/apiList";

export default {
  namespaced: true,
  // state,
  // actions,
  // mutations,
  // getters
  state: {
    nominaciones: [],
    nomRegalias: [],
    nominacion: [],
    ciudades: [],
    bloqueSitios: [],
    loading: false,
    empresas: [],
    programaciones: [],
    programacion: [],
    productos: [],
    tipoVehiculos: [],
    nominacionesList: [],
    nominacionesListFecha: [],
    getTreeProgramacion: [],
  },
  getters: {},
  mutations: {

    
    setNominaciones: (state, nominaciones) => {
      state.nominaciones = nominaciones;
    },
    setNomRegalias: (state, nomRegalias) => {
      state.nomRegalias = nomRegalias;
    },
    setCiudades: (state, ciudades) => {
      state.ciudades = ciudades;
    },
    setLoading: (state, loading) => {
      state.loading = loading;
    },
    setBloqueSitios: (state, bloqueSitios) => {
      state.bloqueSitios = bloqueSitios;
    },
    setEmpresasNom: (state, empresas) => {
      state.empresas = empresas;
    },
    setNominacion: (state, nominacion) => {
      state.nominacion = nominacion;
    },
    setProgramaciones: (state, programaciones) => {
      state.programaciones = programaciones;
    },

    
    setProductos: (state, productos) => {
      state.productos = productos;
    },
    setTipoVehiculos: (state, tipoVehiculos) => {
      state.tipoVehiculos = tipoVehiculos;
    },
    setProgramacion: (state, programacion) => {
      state.programacion = programacion;
    },
   
    setNominacionesList: (state, nominacionesList) => {
      state.nominacionesList = nominacionesList;
    },

    setNominacionesListFecha: (state, nominacionesListFecha) => {
      state.nominacionesListFecha = nominacionesListFecha;
    },

    setTreeProgramacion: (state, treeProgramacion) => {
      state.treeProgramacion = treeProgramacion;
    },
  },
  actions: {
    /*
     * ADMIN HIDROCARBUROS NOMINACION
     */
    actGetNominaciones: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.getNominaciones(data);
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setNominaciones", response);
      return true;
    },

    actSaveNominacion: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.saveNominacion(data);
      commit("setLoading", false);
      return response;
    },

    actGetBloqueSitios: async ({ commit }) => {
      let response = await Api.getBloqueSitios();
      if (response.error) {
        return response.error;
      }
      commit("setBloqueSitios", response);
      return true;
    },

    actSaveNominacionDet: async ({ commit }, data) => {
      let id = data[0];
      let params = data[1];
      commit("setLoading", true);
      let response = await Api.saveNominacionDet(id, params);
      commit("setLoading", false);
      return response;
    },

    actGetEmpresasNom: async ({ commit }, data) => {
      let response = await Api.getEmpresasNom(data);
      if (response.error) {
        return response.error;
      }
      commit("setEmpresasNom", response);
      return true;
    },

    actSaveDetNomEmpresa: async ({ commit }, data) => {
      let idDetNom = data[0];
      let idEmpresa = data[1];
      let params = data[2];
      commit("setLoading", true);
      let response = await Api.saveDetNomEmpresa(idDetNom, idEmpresa, params);
      commit("setLoading", false);
      return response;
    },

    actUploadFile: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.uploadFile(data);
      commit("setLoading", false);
      return response;
    },

    actEditNominacion: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.editNominacion(data);
      commit("setLoading", false);
      return response;
    },

    actDownloadFile: async ({ commit }) => {
      commit("setLoading", true);
      let response = await Api.downloadFile();
      commit("setLoading", false);
      return response;
    },

    actDeleteNominacion: async ({ commit }, id) => {
      commit("setLoading", true);
      let response = await Api.deleteNominacion(id);
      commit("setLoading", false);
      return response;
    },

    actGetNominacion: async ({ commit }, id) => {
      commit("setLoading", true);
      let response = await Api.getNominacion(id);
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setNominacion", response);
      return true;
    },

    actDeleteDetNomEmpresa: async ({ commit }, data) => {
      let idDetNom = data[0];
      let idEmpresa = data[1];
      let response = await Api.deleteDetNomEmpresa(idDetNom, idEmpresa);
      return response;
    },

    /*
     *
     * Hidrocarburos Programaciones
     *
     */

    actGetProgramaciones: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.getProgramaciones(data);
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setProgramaciones", response);
      return true;
    },

    actSaveProgramacion: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.saveProgramacion(data);
      commit("setLoading", false);
      return response;
    },

    actGetProductos: async ({ commit }) => {
      let response = await Api.getProductos();
      if (response.error) {
        return response.error;
      }
      commit("setProductos", response);
      return true;
    },

    actGetTiposVehiculos: async ({ commit }) => {
      let response = await Api.getTiposVehiculos();
      if (response.error) {
        return response.error;
      }
      commit("setTipoVehiculos", response);
      return true;
    },

    actGetProgramacion: async ({ commit }, id) => {
      commit("setLoading", true);
      let response = await Api.getProgramacion(id);
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setProgramacion", response);
      return true;
    },

    actDeleteProgramacion: async ({ commit }, id) => {
      commit("setLoading", true);
      let response = await Api.deleteProgramacion(id);
      commit("setLoading", false);
      return response;
    },

    actGetNominacionesListFecha: async ({ commit }) => {
      commit("setLoading", true);
      let response = await Api.getNominacionesListFecha();
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setNominacionesListFecha", response);
      return true;
    },

    actGetNominacionesList: async ({ commit }) => {
      commit("setLoading", true);
      let response = await Api.getNominacionesList();
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setNominacionesList", response);
      return true;
    },

    actGetTreeProgramacion: async ({ commit }, id) => {
      commit("setLoading", true);
      let response = await Api.getTreeProgramacion(id);
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setTreeProgramacion", response);
      return true;
    },
    /*
     * ADMIN HIDROCARBUROS NOMINACION REGALIAS
     */
    actGetNomRegalias: async ({ commit }, data) => {
      commit("setLoading", true);
      let response = await Api.getNomRegalias(data);
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      commit("setNomRegalias", response);
      return true;
    },
  },
};
