const baseServer = process.env.VUE_APP_API_URL;

export default {
    servicioURL: baseServer,
    servicioApi: baseServer + '/api',
    /*axiosConf: (token) => {
        const config = {
            headers: { 
                'Content-type': 'multipart; form-data; application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET',
                'Access-Control-Allow-Headers': '*',
                'cache-control': 'no-cache',
                'Authorization': '',
            }
        }
        if (token && localStorage.getItem('token') != null) {
            config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
        }
        return config;
    },*/
}