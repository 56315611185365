<template>
  <div id="app">
    <NavBarTop v-if="this.$store.state.auth" />
    <NavBarLeft v-show="this.$store.state.auth" />
    <div
      class="sidebar-mini-xs"
      :class="$store.getters.getDarkMode ? 'dark-mode' : ''"
    >
      <transition name="fade-slide-rigth" mode="out-in">
        <router-view />
      </transition>
    </div>
    <footer
      class="main-footer"
      :class="$store.getters.getDarkMode ? 'dark-mode' : ''"
      v-if="this.$store.state.auth"
    >
      <div class="row">
        <div class="float-left col-md-7 d-sm-inline-block">
          <img
              src="/img/img_feria_sv.png"
              alt="SOS Contingencias"
              style="width: 100px"
            />
        </div>
        <div class="float-right d-sm-inline-block col-md-5">
          <a href="https://soscontingencias.com.co/" target="_blank">
            <img
              :src="
                $store.getters.getDarkMode
                  ? '/img/logo-sos-negativo.png'
                  : '/img/logo-sos.png'
              "
              alt="SOS Contingencias"
              style="width: 60px"
            />
          </a>
          <strong
            >© 2023
            <a href="https://soscontingencias.com.co/" target="_blank"
              >SOS CONTINGENCIAS</a
            >.</strong
          >
          Todos los derechos reservados.
        </div>
      </div>
    </footer>
    <vue-progress-bar />
  </div>
</template>
<style>
.bg-frontera {
  background: linear-gradient(to bottom, #201747, #001871);
}
.bg-frontera-top-left {
  background: linear-gradient(to bottom, #201747, #001871);
}

.card-frontera.card-outline {
  border-top: 3px solid #201747;
}

.card-frontera {
  border-top: linear-gradient(to bottom, #201747, #001871);
  /* border-top: 3px solid #9b0a59; */
}

.fade-slide-rigth-enter-active {
  transition: all 0.5s ease;
}
.fade-slide-rigth-leave-active {
  transition: all 0.5s ease;
}
.fade-slide-rigth-enter,
.fade-slide-rigth-leave-to {
  transform: translateX(5%);
  opacity: 0;
}
.highcharts-data-table table {
  border-collapse: collapse;
  border-spacing: 0;
  background: white;
  min-width: 100%;
  margin-top: 10px;
  font-family: sans-serif;
  font-size: 0.9em;
}
.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  border: 1px solid silver;
  padding: 0.5em;
}
.highcharts-data-table tr:nth-child(even),
.highcharts-data-table thead tr {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #eff;
}
.highcharts-data-table caption {
  border-bottom: none;
  font-size: 1.1em;
  font-weight: bold;
}

.dark-vselect .vs__search::placeholder,
.dark-vselect .vs__dropdown-toggle,
.dark-vselect .vs__dropdown-menu,
.dark-vselect .vs__dropdown-option {
  background: #343a40;
  border: solid 1px #535b62;
  color: #fff;
  text-transform: lowercase;
  font-variant: small-caps;
}

.dark-vselect .vs__selected-options,
.dark-vselect .vs__selected,
.dark-vselect .vs__search,
.dark-vselect .vs__open {
  color: #fff;
}
.dark-vselect .vs__selected {
  background: #454d55;
}

.dark-vselect .vs__dropdown-option:hover {
  background: rgb(0, 4, 63);
}

.dark-vselect .vs__clear,
.dark-vselect .vs__open-indicator,
.dark-vselect .vs__deselect {
  fill: #fff;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  background: #b7b5b5 !important;
}

.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100%);
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
<script>
import NavBarTop from "./components/layout/NavBarTop";
import NavBarLeft from "./components/layout/NavBarLeft";

export default {
  async mounted() {
    this.$Progress.finish();
    await this.$store.dispatch("getUser");
    await this.$store.dispatch("getPermissions");
  },
  data() {
    return {
      darkMode: false,
    };
  },
  created() {
    this.$Progress.start();

    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }

      this.$Progress.start();
      //  continue to next page
      next();
    });

    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
  methods: {
    darkModeChange(dato) {
      this.darkMode = dato;
      this.$store.dispatch("setDarkMode", dato);
    },
  },

  components: {
    NavBarTop,
    NavBarLeft,
  },
};
</script>
