import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from "../router/index.js";

/***
 * STORE MODULOS
 * */
import modSeguridad from "./modSeguridad/index";
import modOperacion from "./modOperacion/index";
import modHidrocarburos from "./modHidrocarburos/index";
import modTep from "./modTep/index";
/**
 *
 */

import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.VUE_APP_API_URL; /* "http://eaead65417dc.ngrok.io/"; */
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Manejar el error aquí
    if (error.response) {
      // La solicitud fue realizada, pero el servidor respondió con un código de estado fuera del rango 2xx

      const status = error.response.status;

      if (status === 401) {
        // this.dispatch("logout");
        // console.error("Status:", error.response.status);

        let rutasNo = [
          "login",
          "MaterialEvaluacion",
          "PoliticaTratamientoDatos",
          "EvaluacionConductor",
          "ForgotPassword",
          "ResetPassword",
          "/",
        ];

        if (!rutasNo.some((ruta) => router.currentRoute.path.includes(ruta))) {
          alert("Su sesión ha caducado, por favor vuelva a ingresar");
          window.location.href = "/login";
        }
      }
    } else if (error.request) {
      // La solicitud fue realizada pero no se recibió respuesta
      //console.error('Request:', error.request);
    } else {
      // Algo sucedió en la configuración de la solicitud que desencadenó un error
      //console.error('Error:', error.message);
    }
    //console.error('Config:', error.config);

    // Retornar una promesa rechazada
    return Promise.reject(error);
  }
);

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: null,
    errors: null,
    mensaje_login: null,
    auth: false,
    authAzure: false,
    permissions: [],
    menus: [],
    routes: [],
    msalConfig: {
      auth: {
        clientId: "c84e78a2-bc17-4be6-a1b6-afa1905639bf",
        authority:
          "https://login.microsoftonline.com/2637d50c-c560-436c-8997-ebe63c9656fd",
        redirectURI: "https://pegaso.fronteraenergy.ca",
      },
      cache: {
        cacheLocation: "localStorage",
      },
    },
    accessToken: "",
    darkMode: false,
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    SET_USER(state, user) {
      state.user = user;
      state.auth = Boolean(user);
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_MENUS(state, menus) {
      state.menus = menus;
    },
    SET_ROUTES(state, routes) {
      state.routes = routes;
    },
    SET_ERRORS(state, error) {
      state.errors = error;
    },
    SET_MENSAJE_LOGIN(state, mensaje) {
      state.mensaje_login = mensaje;
    },
    SET_DARK_MODE(state, darkMode) {
      state.darkMode = darkMode;
    },
    SET_AUTH_AZURE(state, authAzure) {
      state.authAzure = authAzure;
    },
  },
  actions: {
    async login({ dispatch, commit }, credentials) {
      await axios.get("/sanctum/csrf-cookie");
      await axios
        .post("/login", credentials)
        .then(async (res) => {
          await dispatch("setAuthAzure", false);
          await dispatch("setDarkMode", false);
          await dispatch("getUser");
          await dispatch("getPermissions");
        })
        .catch(async (error) => {
          let res = await Object.assign({}, error);
          let err_pss_date = res.response.data.errors.password_updated_at;
          let err_recaptcha = res.response.data.errors.recaptcha;
          let err_status = res.response.data.errors.status;
          let err_estado_empresa = res.response.data.errors.estado_empresa;
          let err_email = res.response.data.errors.email;
          if (typeof err_pss_date !== "undefined") {
            let errors = "password_updated_at";
            commit("SET_ERRORS", errors);
          }
          if (typeof err_recaptcha !== "undefined") {
            let errors = "recaptcha";
            commit("SET_ERRORS", errors);
          }
          if (typeof err_status !== "undefined") {
            let errors = "status";
            commit("SET_ERRORS", errors);
          }
          if (typeof err_estado_empresa !== "undefined") {
            let errors = "estado_empresa";
            commit("SET_ERRORS", errors);
          }
          if (typeof err_email !== "undefined") {
            let errors = "email";
            let mensaje = res.response.data.errors.email;
            commit("SET_ERRORS", errors);
            commit("SET_MENSAJE_LOGIN", mensaje);
          }
        });
    },

    async loginAzure({ dispatch, commit }, cuentaAzure) {
      await axios.get("/sanctum/csrf-cookie");
      await axios
        .post("/loginAzure", {
          email: cuentaAzure.username,
          name: cuentaAzure.name,
        })
        .then(async (res) => {
          await dispatch("setAuthAzure", true);
          await dispatch("setDarkMode", false);
          await dispatch("getUser");
          await dispatch("getPermissions");
        })
        .catch(async (error) => {
          //falta validar error
          let res = await Object.assign({}, error);
          let err_recaptcha = res.response.data.errors.recaptcha;
          let err_status = res.response.data.errors.status;
          let err_email = res.response.data.errors.email;
          let user_inactive = res.response.data.error_user_inactive;

          if (typeof err_recaptcha !== "undefined") {
            let errors = "recaptcha";
            commit("SET_ERRORS", errors);
          }
          if (typeof err_status !== "undefined") {
            let errors = "status";
            commit("SET_ERRORS", errors);
          }

          if (typeof user_inactive !== "undefined") {
            let errors = "status";
            commit("SET_ERRORS", errors);
          }

          if (typeof err_email !== "undefined") {
            let errors = "email";
            let mensaje = res.response.data.errors.email;
            commit("SET_ERRORS", errors);
            commit("SET_MENSAJE_LOGIN", mensaje);
          }
        });
    },

    async getUser({ commit }) {
      await axios
        .get("/api/user")
        .then((res) => {
          commit("SET_USER", res.data);
        })
        .catch(() => {
          commit("SET_USER", null);
        });
    },

    async getPermissions({ commit }) {
      await axios
        .get("/api/user/permissions")
        .then((res) => {
          commit("SET_PERMISSIONS", res.data);
        })
        .catch(() => {
          commit("SET_PERMISSIONS", []);
        });
    },

    async logout({ dispatch }) {
      await axios.post("/logout");
      await dispatch("getMenus", null);
      await dispatch("getPermissions");
      await dispatch("getUser");
      await dispatch("setDarkMode", false);
    },

    async getMenus({ commit }, linea_negocio) {
      await axios
        .get("/api/menu", {
          params: {
            linea_negocio: linea_negocio,
          },
        })
        .then((response) => {
          commit("SET_MENUS", response.data);
        })
        .catch(() => {
          commit("SET_MENUS", []);
        });
    },

    async getRoutes({ commit }, menus) {
      var r = [];
      menus.forEach((menu) => {
        menu.nivel2.forEach((nivel2) => {
          nivel2.nivel3.forEach((nivel3) => {
            nivel3.rutas.forEach((ruta) => {
              r.push(ruta);
            });
          });
        });
      });
      commit("SET_ROUTES", r);
    },

    async setRoutes() {
      this.getters.getRoutes.forEach((dataRoutes) => {
        var newRoute = {
          path: dataRoutes.path,
          component: () => import("../views/" + dataRoutes.component + ".vue"),
          name: dataRoutes.name,
        };
        router.addRoutes([newRoute]);
      });
    },

    async setDarkMode({ commit }, darkMode) {
      commit("SET_DARK_MODE", darkMode);
    },
    async setAuthAzure({ commit }, authAzure) {
      commit("SET_AUTH_AZURE", authAzure);
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.auth;
    },
    getRoutes: (state) => {
      return state.routes;
    },
    getMenus: (state) => {
      return state.menus;
    },
    getUser: (state) => {
      return state.user;
    },
    getErrors: (state) => {
      return state.errors;
    },
    getPermissions: (state) => {
      return state.permissions;
    },
    getMensajeLogin: (state) => {
      return state.mensaje_login;
    },
    can: (state) => (permissionName) => {
      return (
        state.permissions.find((per) => per.name === permissionName) !==
        undefined
      );
    },
    getDarkMode: (state) => {
      return state.darkMode;
    },
    getAuthAzure: (state) => {
      return state.authAzure;
    },
  },

  // Registro de modulos
  modules: {
    modSeguridad: modSeguridad,
    modOperacion: modOperacion,
    modHidrocarburos: modHidrocarburos,
    modTep: modTep,
  },
});
