import Api from "./../../services/apiList";

export default {
  namespaced: true,
  // state,
  // actions,
  // mutations,
  // getters
  state: {
    ciudades: [],
    listas: [],
    sitios: [],
    inspecciones: [],
    puntosGestion: [],
    puntoGestion: [],
    empresas: [],
    loading: false,
    errorMsj: "",
  },
  getters: {},
  mutations: {
    setCiudades: (state, ciudades) => {
      state.ciudades = ciudades;
    },
    setListas: (state, listas) => {
      state.listas = listas;
    },
    setSitios: (state, sitios) => {
      state.sitios = sitios;
    },
    setInspecciones: (state, inspecciones) => {
      state.inspecciones = inspecciones;
    },
    setPuntosGestion: (state, puntosGestion) => {
      state.puntosGestion = puntosGestion;
    },
    setPuntoGestion: (state, puntoGestion) => {
      state.puntoGestion = puntoGestion;
    },
    setLoading: (state, loading) => {
      state.loading = loading;
    },
    setEmpresas: (state, empresas) => {
      state.empresas = empresas;
    },
  },
  actions: {
    /*
     * ADMIN OPERACIONES
     */
    actGetCiudades: async ({ commit }) => {
      let response = await Api.getCiudades();
      if (response.error) {
        return response.error;
      }
      commit("setCiudades", response);
      return true;
    },

    actGetListas: async ({ commit }, id) => {
      let response = await Api.getListas(id);
      if (response.error) {
        return response.error;
      }
      commit("setListas", response);
      return true;
    },

    actGetEmpresas: async ({ commit }) => {
      let response = await Api.getEmpresas();
      if (response.error) {
        return response.error;
      }
      commit("setEmpresas", response);
      return true;
    },

    actGetSitios: async ({ commit }) => {
      let response = await Api.getSitios();
      if (response.error) {
        return response.error;
      }
      commit("setSitios", response);
      return true;
    },

    actGetInspeccion: async ({ commit }) => {
      let response = await Api.getInspeccion();
      if (response.error) {
        return response.error;
      }
      commit("setInspecciones", response);
      return true;
    },

    actSavePuntoGestion: async ({ commit }, data) => {
      //commit('setLoading', true);
      let response = await Api.savePuntoGestion(data);
      //commit('setLoading', false);
      if (response.error) {
        return response.error;
      }
      return true;
    },

    actGetPuntosGestion: async ({ commit }, data) => {
      let response = await Api.getPuntosGestion(data);
      if (response.error) {
        return response.error;
      }
      commit("setPuntosGestion", response);
      return true;
    },

    actDelPuntoGestion: async ({ commit }, id) => {
      commit("setLoading", true);
      let response = await Api.delPuntoGestion(id);
      commit("setLoading", false);
      if (response.error) {
        return response.error;
      }
      return true;
    },

    actGetPuntoGestion: async ({ commit }, id) => {
      let response = await Api.getPuntoGestion(id);
      if (response.error) {
        return response.error;
      }
      commit("setPuntoGestion", response);
      return true;
    },

    actUpdtPuntoGestion: async ({ commit }, data) => {
      //commit('setLoading', true);
      let response = await Api.updtPuntoGestion(data);
      //commit('setLoading', false);
      if (response.error) {
        return response.error;
      }
      return true;
    },
  },
};
