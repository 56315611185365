<template>
  <div class="sidebar-container">
    <aside
      class="main-sidebar sidebar-dark-primary elevation-4 text-xs bg-frontera-top-left"
    >
      <!-- Brand Logo -->
      <a data-widget="pushmenu" href="#" role="button" class="brand-link">
        <img
          src="/img/icons/pegaso-icon.png"
          alt="Pegaso"
          class="brand-image img-circle elevation-3 ml-0"
          style="width: 50px"
        />
        <span class="brand-text font-weight-light">Pegaso</span>
      </a>

      <!-- Sidebar -->
      <div id="sidebar" class="sidebar">
        <!-- SidebarSearch Form -->
        <div class="form-inline">
          <div class="input-group mt-2">
            <input
              class="form-control form-control-sidebar"
              type="search"
              placeholder="Buscar"
              aria-label="Search"
              v-model="filtro"
            />
            <div class="input-group-append">
              <button class="btn btn-sidebar">
                <i class="fas fa-search fa-fw"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul
            class="nav nav-pills nav-sidebar flex-column nav-flat nav-compact nav-child-indent"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li
              class="nav-item has-treeview"
              v-for="menu in filtrarMenu"
              :key="menu.id"
            >
              <a href="#" class="nav-link">
                <i
                  class="nav-icon"
                  :class="menu.icono == 'a' ? 'fas fa-circle' : menu.icono"
                ></i>
                <p>
                  {{ menu.nombre }}
                  <i class="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul class="nav nav-treeview" v-if="menu.nivel2.length > 0">
                <li
                  class="nav-item"
                  v-for="nivel2 in menu.nivel2"
                  :key="nivel2.id"
                >
                  <a href="#" class="nav-link">
                    <i
                      class="nav-icon"
                      :class="
                        nivel2.icono == 'a' ? 'far fa-circle' : nivel2.icono
                      "
                    ></i>
                    <p>{{ nivel2.nombre }}</p>
                    <i
                      class="right fas fa-angle-left"
                      v-if="nivel2.nivel3.length > 0"
                    ></i>
                  </a>
                  <ul class="nav nav-treeview" v-if="nivel2.nivel3.length > 0">
                    <li
                      class="nav-item"
                      v-for="nivel3 in nivel2.nivel3"
                      :key="nivel3.id"
                    >
                      <router-link :to="nivel3.ruta" class="nav-link">
                        <i
                          class="nav-icon"
                          :class="
                            nivel3.icono == 'a'
                              ? 'far fa-dot-circle'
                              : nivel3.icono
                          "
                        ></i>
                        <p>{{ nivel3.nombre }}</p>
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <div class="sidebar-footer brand-link text-center">
        <a href="https://soscontingencias.com.co/" target="_blank">
          <img
            src="/img/logo-sos-negativo.png"
            alt="SOS Contingencias"
            style="width: 55px"
          />
        </a>
      </div>
      <!-- /.sidebar -->
    </aside>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filtro: "",
    };
  },

  computed: {
    filtrarMenu() {
      if (this.$store.state.menus) {
        return this.$store.state.menus
          .map((res1) => {
            const nuevosNiveles2 = res1.nivel2
              .map((res2) => {
                const nuevosNiveles3 = res2.nivel3
                  .map((res3) => {
                    const nMenu = res3.nombre.toString().toLowerCase();
                    const search = this.filtro.toLowerCase();
                    return nMenu.includes(search) ? res3 : null;
                  })
                  .filter(Boolean); // Filtra los elementos nulos

                return nuevosNiveles3.length > 0
                  ? { ...res2, nivel3: nuevosNiveles3 }
                  : null;
              })
              .filter(Boolean); // Filtra los elementos nulos

            return nuevosNiveles2.length > 0
              ? { ...res1, nivel2: nuevosNiveles2 }
              : null;
          })
          .filter(Boolean); // Filtra los elementos nulos
      } else {
        return this.$store.state.menus;
      }
    },
  },
};
</script>
<style>
.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar {
  position: relative;
  flex: 1; /* Para que ocupe todo el espacio restante dentro de .sidebar-container */
  max-height: calc(
    100% - 100px
  ); /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto; /* Agrega desplazamiento vertical si el contenido excede la altura máxima */
}
</style>
