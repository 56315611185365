import Api from './../../services/apiList'

export default {
  namespaced: true,
  // state,
  // actions,
  // mutations,
  // getters
  state: {
    roles: [],
    permisos: {},
    loading: false,
    errorMsj: '',
  },
  getters: {},
  mutations: {
    setRoles: (state, roles) => {
      state.roles = roles;
    },
    setPermisos: (state, permisos) => {
      state.permisos = permisos;
    },
    setLoading: (state, loading) => {
      state.loading = loading;
    },
  },
  actions: {
    /*     
     * ADMIN ROLES      
     */
    actGetRoles: async ({
      commit
      }) => {
        commit('setLoading', true);
        let response = await Api.getRoles();
        commit('setLoading', false);
        if (response.error) {
          return response.error;
        }
        commit('setRoles', response);
        return true;
    },

    actSaveRoles: async ({
      commit
      }, data) => {
        let response = await Api.saveRoles(data);
        if (response.error) {
          return response.error;
        }
        return true;
    },

    actEditRoles: async ({
      commit
      }, data) => {
        let response = await Api.editRoles(data);
        if (response.error) {
          return response.error;
        }
        return true;
    },

    actDeleteRoles: async ({
      commit
      }, id) => {
        let response = await Api.deleteRoles(id);
        if (response.error) {
          return response.error;
        }
        return true;
    },

    actGetPermisos: async ({
      commit
      }, id) => {
        commit('setLoading', true);
        let response = await Api.getPermisos(id);
        commit('setLoading', false);
        if (response.error) {
          return response.error;
        }
        commit('setPermisos', response);
        return true;
    },

    actSavePermisos: async ({
      commit
      }, data) => {
        commit('setLoading', true);
        let response = await Api.savePermisos(data);
        commit('setLoading', false);
        if (response.error) {
          return response.error;
        }
        return true;
    },
  },
}