<template>
  <div>
    <div class="modal fade" id="modal-actualizar-clave">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Actualizar Contraseña</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-group">
              <label for="password">Contraseña Actual</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control form-control-sm"
                  v-model="form.password_current"
                  :class="$v.form.password_current.$invalid ? 'is-invalid' : 'is-valid'"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <p class="bg-danger" v-if="!$v.form.password_current.required"
                >Contraseña es requerido.</p>
            </div>
            <div class="form-group">
              <label for="password">Contraseña</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control form-control-sm"
                  v-model="form.password"
                  :class="$v.form.password.$invalid ? 'is-invalid' : 'is-valid'"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <p class="bg-danger" v-if="!$v.form.password.required"
                >Contraseña es requerido.</p
              >
              <p
                class="bg-danger"
                v-if="!$v.form.password.strongPassword"
                >
                La contraseña no cumple con los parametros de seguridad
                <ul>
                  <li>Debe tener al menos una letra en mayuscula</li>
                  <li>Debe tener al menos una letra en minuscula</li>
                  <li>Debe tener al menos un numero</li>
                  <li>Debe tener al menos un caracter especial</li>
                  <li>Debe tener al 12 caracteres</li>
                </ul>
              </p
              >

            </div>

            <div class="form-group">
              <label for="password_confirmation">Confirme Contraseña</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control form-control-sm"
                  v-model="form.password_confirmation"
                  :class="
                    $v.form.password_confirmation.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <p
                class="bg-danger"
                v-if="!$v.form.password_confirmation.sameAsPassword"
              >
                Las contraseñas deben ser iguales.
              </p>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-success"
              v-show="!$v.form.$invalid"
              @click="savePassword()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  name: "UpdatePassword",
  data: () => ({
    form: {
      email: {},
      password: "",
      password_confirmation: "",
      password_current: ""
    }
  }),
  validations: {
    form: {
      email: {
        required
      },
      password: {
        required,
        minLength: minLength(12),
        strongPassword(password) {
          return (
            /[a-z]/.test(password) && //checks for a-z
            /[A-Z]/.test(password) && //checks for a-z
            /[0-9]/.test(password) && //checks for 0-9
            /\W|_/.test(password) && //checks for special char
            password.length >= 6
          );
        }
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password")
      },
      password_current:{
        required,        
      }
    }
  },
  methods: {
    savePassword() {
      let data = this.form;
      axios
        .post("/api/password/update", data, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          if (!response.data.user_error) {
            document.getElementById("close-modal").click();
            this.form = {};
            this.$swal({
              icon: "success",
              title: response.data.success,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {         
            this.form.password_current = "";   
            this.$swal({
              icon: "error",
              title: response.data.user_error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }                  
        })
        .catch(e => {
          this.form = {};
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        });
    }
  },
  
  mounted() {
    this.form.email = this.$store.getters.getUser.email;
  }
};
</script>
