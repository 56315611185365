import Axios from 'axios';
import config from './config'

//CALLBACKS
const callback = {
    success: (response) => {
        return response.data;
    },
    /*errorConn: (error) => {
        return false;
    }*/
}

//all services of Permisos , data
export default {
    get: (uri,data) => {
        const request = Axios.get(config.servicioApi + uri,data);
        return request
            .then(callback.success)
            .catch(callback.errorConn);
    },    
    post: (uri, /*token,*/ data) => { 
        const request = Axios.post(config.servicioApi + uri, data, /*config.axiosConf(token)*/);
        return request
            .then(callback.success)
            .catch(callback.errorConn);
    },
    put: (uri, /*token,*/ data) => {
        const request = Axios.put(config.servicioApi + uri, data, /*config.axiosConf(token)*/);
        return request
            .then(callback.success)
            .catch(callback.errorConn);
    },
    patch: (uri, /*token,*/ data) => {
        const request = Axios.patch(config.servicioApi + uri, data, /*config.axiosConf(token)*/);
        return request
            .then(callback.success)
            .catch(callback.errorConn);
    },
    delete: (uri, /*token,*/ data) => {
        const request = Axios.delete(config.servicioApi + uri, data, /*config.axiosConf(token)*/);
        return request
            .then(callback.success)
            .catch(callback.errorConn);
    },
}